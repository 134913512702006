import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

// Import SVG icons
import relevanceIcon from '../../assets/img/icons/svg/relevance.svg';
import impactIcon from '../../assets/img/icons/svg/impact.svg';
import methodsIcon from '../../assets/img/icons/svg/methods.svg';

import MissionItem from './MissionItem';

import './Mission.css';

const Mission = () => (
    <section id="mission" className="py-5 bg-light">
        <Container className="mission-container">
            <Row className="mb-5 mission-heading-container px-3">
                <Col>
                    <h2 className="h1 text-center text-primary">Our Mission</h2>
                    <p className="mission-subheading fs-4">
                        Our mission is to create a collaborative platform that
                        empowers communities, policymakers, and researchers with
                        AI-driven insights to support informed decision-making
                        and sustainable development across Colorado.
                    </p>
                </Col>
            </Row>
            <Row>
                <Col>
                    <MissionItem
                        icon={relevanceIcon}
                        title="Relevance"
                        description={
                            <p className="mb-2 mb-md-3">
                                Colorado is an important geographic region,
                                developing rapidly across critical systems urban
                                and rural communities. Existing efforts to
                                understand and increase sustainability are often
                                siloed by sectors, causing gaps in our ability
                                to integrate them at a regional scale.
                            </p>
                        }
                    />
                    <MissionItem
                        icon={impactIcon}
                        title="Impact"
                        description={
                            <>
                                <p className="mb-2 mb-md-3">
                                    The Sustainability Hub will provide a common
                                    platform for researchers, policy makers, and
                                    community members to discover connections,
                                    and contribute new data.
                                </p>
                                <p />
                            </>
                        }
                    />
                    <MissionItem
                        icon={methodsIcon}
                        title="Methods"
                        description={
                            <>
                                <p className="mb-2 mb-md-3">
                                    Our goal is to create a natural language
                                    based query system that is approachable to
                                    anyone, leveraging advanced machine learning
                                    techniques.
                                </p>
                                <p />
                            </>
                        }
                    />
                </Col>
            </Row>
        </Container>
    </section>
);
export default Mission;
