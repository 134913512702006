import React, { useEffect } from 'react';
import PropTypes from 'prop-types'; // Import PropTypes
import { Modal, Container, Row, Col, Button } from 'react-bootstrap';

import './PopUpPage.css';

import NavBar from '../../navBar/NavBar';
import eventView from '../../assets/event/eventView.png';

export default function PopUpPage(props) {
    const isEvent = props?.event;
    const detail = props?.detail;
    let imagePath = '';

    const handleImageSource = () => {
        if (detail?.imgSrc === '') {
            return imagePath;
        } else if (detail?.imgSrc.startsWith('http')) {
            imagePath = detail?.imgSrc;
            return imagePath;
        } else if (detail?.imgSrc.startsWith('assets/')) {
            imagePath = require(`../../${detail?.imgSrc}`);
            return imagePath;
        } else if (detail?.imgSrc) {
            if (isEvent === 'true') {
                imagePath = require(`../../assets/event/${detail?.imgSrc}`);
            }
            if (isEvent === 'false') {
                imagePath = require(`../../assets/project/${detail?.imgSrc}`);
            }
            return imagePath;
        } else {
            return (imagePath = detail?.imgSrc);
        }
    };

    useEffect(() => {}, [isEvent]);

    if (!detail) {
        return <div>No details found.</div>;
    }
    handleImageSource();
    return (
        <Modal
            {...props}
            fullscreen
            className="pop-modal"
            aria-labelledby="contained-modal-title-vcenter"
        >
            <NavBar />

            <Modal.Body className="bg-light">
                <Container>
                    <Row>
                        <Modal.Title
                            id="contained-modal-title-vcenter"
                            className="  text-center display-6 fw-bold"
                        >
                            {detail?.title}
                        </Modal.Title>
                        {detail.author && (
                            <p className="text-muted text-center h5 mt-2">
                                By {detail.author}
                            </p>
                        )}
                    </Row>
                    <Row className="text-center gx-5">
                        <Col
                            sm={12}
                            md={12}
                            lg={6}
                            className="popup-image my-3"
                        >
                            <img
                                src={imagePath ? imagePath : eventView}
                                alt="Highlight"
                            />
                        </Col>
                        <Col md={12} lg={6} className="popup-detail">
                            {detail?.subHeading && (
                                <p className="event-subHeading">
                                    {detail.subHeading}
                                </p>
                            )}
                            <div
                                className={`event-schedule mt-4 ${isEvent === 'true' ? '' : 'd-none'}`}
                            >
                                <h2 className="bg-white">Schedule</h2>
                                <h3 className="bg-primary text-white">
                                    {detail?.primarySchedule}
                                </h3>
                                <p className="text-start bg-white">
                                    {detail?.primaryDescription}
                                </p>
                                <h3 className="bg-secondary">
                                    {detail?.secondarySchedule}
                                </h3>
                                <p className="text-start bg-white">
                                    {detail?.secondaryDescription}
                                </p>
                            </div>
                            <div
                                className={`project-subHeadings text-start mt-4 ${isEvent === 'true' ? 'd-none' : ''}`}
                            >
                                {detail.body?.map((section, index) => {
                                    if (typeof section === 'string') {
                                        return <p key={index}>{section}</p>;
                                    } else if (typeof section === 'object') {
                                        return (
                                            <div key={index}>
                                                {section.subHeading && (
                                                    <h3 className="h5">
                                                        {section.subHeading}
                                                    </h3>
                                                )}
                                                {section.content &&
                                                    section.content.map(
                                                        (paragraph, idx) => (
                                                            <p key={idx}>
                                                                {paragraph}
                                                            </p>
                                                        )
                                                    )}
                                            </div>
                                        );
                                    }
                                    return null;
                                })}
                            </div>
                        </Col>
                    </Row>
                </Container>
            </Modal.Body>

            <Modal.Footer className="bg-light">
                <Button
                    onClick={props.onHide}
                    className="m-auto pop-back-button"
                >
                    Back
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

// Correct PropTypes validation
PopUpPage.propTypes = {
    event: PropTypes.string, // event is a string based on the 'isEvent' logic
    detail: PropTypes.shape({
        imgSrc: PropTypes.string, // imgSrc is a string, may be an empty string or a URL
        title: PropTypes.string, // title is a string
        subHeading: PropTypes.string, // subHeading is a string
        primarySchedule: PropTypes.string, // primarySchedule is a string
        primaryDescription: PropTypes.string, // primaryDescription is a string
        secondarySchedule: PropTypes.string, // secondarySchedule is a string
        secondaryDescription: PropTypes.string, // secondaryDescription is a string
        author: PropTypes.string, //author is a string
        body: PropTypes.arrayOf(
            PropTypes.oneOfType([
                PropTypes.string, // body is either a string or a nested obect of sub-heading and content array of strings
                PropTypes.shape({
                    subHeading: PropTypes.string,
                    content: PropTypes.arrayOf(PropTypes.string),
                }),
            ])
        ).isRequired,
    }).isRequired, // detail is required
    onHide: PropTypes.func.isRequired, // onHide is a required function
};
